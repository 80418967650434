import gql from 'graphql-tag'

export const GET_HOMEPAGE_MAJORS = gql`
  {
    studyFields(showOnHomepage: true) {
      id
      name
      parentName
      path
      thumbnail {
        url(size: "512x256#c")
      }
      slug
      liked
      summary
      iconName
    }
  }
`

export const GET_HOMEPAGE_CAREERS = gql`
  {
    careers(showOnHomepage: true) {
      id
      name
      fieldName
      iconName
      path
      minSalary
      maxSalary
      thumbnail {
        url(size: "512x256#c")
      }
      slug
      liked
      summary
    }
  }
`

export const GET_HOMEPAGE_SEO_TEXT = gql`
  {
    homepage {
      seoText
    }
  }
`

export const GET_HOMEPAGE_TESTS = gql`
  {
    assessments(showOnHomepage: true) {
      id
      name
      action
      actionUrl
      label
      image {
        url(size: "450x256#c")
      }
      recordLead
      slug
      summary
    }
  }
`

export const GET_ARTICLE_TAGS = gql`
  {
    homepage {
      articleTags
    }
  }
`

export const GET_HOMEPAGE_CITIES = gql`
  {
    homepageCities {
      id
      bannerImg {
        url(size: "450x500#c")
      }
      country
      name
      description
      url
    }
  }
`

export const GET_HOMEPAGE_CAROUSEL_BANNERS = gql`
  query QueryType($imageDesktopSize: String!, $imageMobileSize: String!) {
    banners(forHomepage: true) {
      id
      name
      size
      action
      url
      brochure {
        url
      }
      bigImageDesktop {
        url(size: $imageDesktopSize)
      }
      bigImageMobile {
        url(size: $imageMobileSize)
      }
      recordLead
      homepageType
    }
  }
`

export const GET_OTHER_SERVICES_TITLE = gql`
  {
    homepage {
      otherServicesTitle
    }
  }
`
