import gql from 'graphql-tag'

export const LIKE_SCHOOL = gql`
  mutation LikeSchool($id: String!) {
    likeSchool(input: { id: $id }) {
      id
      kind
      liked
    }
  }
`
