import React, { FC } from 'react'
import { css } from '@emotion/core'

const recommendedCardsWrapperStyle = css`
  padding: 16px 0;
  @media screen and (max-width: 1279px) {
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media screen and (min-width: 1280px) {
    padding: 24px 0;
    .campus-flex .campus-flex-item:nth-of-type(n + 6) {
      display: none;
    }
  }
`

const RecommendedCardsWrapper: FC = ({ children }) => (
  <div className="recommended-cards-wrapper" css={recommendedCardsWrapperStyle}>
    {children}
  </div>
)

export default RecommendedCardsWrapper
