import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/core'

type SectionContainerProps = {
  backgroundColor?: string
  withPaddingBottom?: boolean
  smallPadding?: boolean
  tagName?: keyof JSX.IntrinsicElements
  children: ReactNode
}

const sectionContainerStyle = (withPaddingBottom: boolean, backgroundColor: string, smallPadding: boolean) => {
  if (smallPadding) {
    return css`
      padding: 24px 0 ${withPaddingBottom ? '24px' : 0} 0;
      background-color: ${backgroundColor};
      @media screen and (min-width: 768px) {
        padding: 40px 0 ${withPaddingBottom ? '40px' : 0} 0;
      }
    `
  }
  return css`
    padding: 40px 0 ${withPaddingBottom ? '40px' : 0} 0;
    background-color: ${backgroundColor};
    @media screen and (min-width: 768px) {
      padding: 80px 0 ${withPaddingBottom ? '80px' : 0} 0;
    }
  `
}

const SectionContainer: FC<SectionContainerProps> = ({
  tagName = 'div',
  backgroundColor = '#fff',
  withPaddingBottom = false,
  smallPadding = false,
  children,
  ...otherProps
}) => {
  const Wrapper = tagName as 'div' // ref: https://stackoverflow.com/a/55970164

  return (
    <Wrapper
      className="section-container"
      css={sectionContainerStyle(withPaddingBottom, backgroundColor, smallPadding)}
      {...otherProps}
    >
      {children}
    </Wrapper>
  )
}

export default SectionContainer
