import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/core'

type BasicContainerProps = {
  tagName?: keyof JSX.IntrinsicElements
  children: ReactNode
}

const containerBaseStyle = css`
  padding: 0 16px;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
`

const BasicContainer: FC<BasicContainerProps> = ({ tagName = 'div', children, ...otherProps }) => {
  const Wrapper = tagName as 'div'

  return (
    <Wrapper className="basic-container" css={containerBaseStyle} {...otherProps}>
      {children}
    </Wrapper>
  )
}

export default BasicContainer
