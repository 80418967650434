import gql from 'graphql-tag'

export const GET_SCHOOLS_RECOMMENDATION = gql`
  query QueryType($page: String!, $imagesSize: String!, $logoSize: String!) {
    schoolsRecommendation(page: $page) {
      id
      images {
        url(size: $imagesSize)
      }
      logo {
        url(size: $logoSize, forcePng: true)
      }
      name
      liked
      sector
      slug
      summary
    }
  }
`
