import React, { useEffect, useState, FC } from 'react'

import SectionTitle from 'components/common/atoms/SectionTitle'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Container from 'components/layouts/atoms/Container'
import CampusCard from 'components/cards/organisms/CampusCard'
import CardFlexItemWrapper from 'components/cards/atoms/CardFlexItemWrapper'
import Loader from 'components/common/atoms/Loader'
import RecommendedCardsWrapper from 'components/common/atoms/RecommendedCardsWrapper'
import TextLink from 'components/common/atoms/TextLink'

import { css } from '@emotion/core'
import { useQuery } from '@apollo/react-hooks'
import { GET_ARTICLE_TAGS } from '@graphql/queries/homepageQueries'
import BasicContainer from 'components/common/atoms/BasicContainer'
import { triggerEvent } from 'utils/dataLayer'
import { useRouter } from 'next/router'

type ArticleItem = {
  link: string
  title: string
  id: number
  thumbnail: {
    url: string
    alt: string
  }
  description: string
}

type RecommendedArticlesProps = {
  title?: string
  backgroundColor?: string
  customTags?: string[]
  eventParameter?: LooseObj
}

const RecommendedArticles: FC<RecommendedArticlesProps> = ({
  backgroundColor = 'transparent',
  customTags = [],
  title = 'Bacaan Untukmu',
  eventParameter
}) => {
  const [articles, setArticles] = useState<ArticleItem[]>([])
  const [fetchStatus, setFetchStatus] = useState<'pending' | 'success' | 'error'>('pending')

  const { data } = useQuery(GET_ARTICLE_TAGS)

  const articlesSource = 'https://quipperhome.wpcomstaging.com/wp-json/wp/v2/posts'
  const articleTags = customTags?.length > 0 ? customTags : data?.homepage?.articleTags

  const fetchArticle = async () => {
    const dataOpts: LooseObj = {
      _embed: 'true',
      // eslint-disable-next-line @typescript-eslint/camelcase
      per_page: '5',
      callback: '?',
    }
    if (customTags?.length === 0) {
      dataOpts.categories = '679384864'
    }

    const articlesURL = new URL(`${articlesSource}`)
    Object.keys(dataOpts).forEach((key) => {
      articlesURL.searchParams.append(key, dataOpts[key])
    })
    articleTags
      ? articleTags.forEach((tag: string) => {
          articlesURL.searchParams.append('tags[]', tag)
        })
      : articlesURL.searchParams.append('categories', '679384864')

    const fetchedArticles = await fetch(articlesURL.href)
    const fetchedArticlesJson = await fetchedArticles.json()

    const formattedArticlesData: ArticleItem[] = fetchedArticlesJson.map((article: LooseObj) => {
      const featuredMedia = article._embedded['wp:featuredmedia'] && article._embedded['wp:featuredmedia'][0]
      const featuredMediaUrl = (() => {
        if (featuredMedia && featuredMedia.media_details?.sizes?.medium) {
          return {
            url: featuredMedia.media_details.sizes.medium.source_url,
            alt: featuredMedia.alt_text,
          }
        } else if (featuredMedia && featuredMedia.source_url) {
          return {
            url: `https://i2.wp.com/${featuredMedia.source_url.slice(8)}?fit=320%2C160&ssl=1`,
            alt: featuredMedia.alt_text,
          }
        }
        return {
          url: '',
          alt: article.title,
        }
      })()
      return {
        id: article?.id,
        title: article?.title?.rendered,
        link: article?.link?.replace('quipperhome.wpcomstaging.com/', 'www.quipper.com/id/blog/'),
        thumbnail: featuredMediaUrl,
        description: article?.excerpt?.rendered,
      }
    })
    setArticles(formattedArticlesData)
    setFetchStatus('success')
  }

  useEffect(() => {
    fetchArticle()
  }, [])

  const router = useRouter()
  const onClick = (title: string) => {
    const parameter = { article_title: title }
    if (router.asPath === '/') {
      triggerEvent('home_article_rec', parameter)
    } else if (router.pathname === '/majors/[slug]') {
      triggerEvent('major_article_rec', { ...eventParameter, ...parameter })
    }
  }

  return (
    <div className="recommended-articles" id="related-articles">
      <SectionContainer backgroundColor={backgroundColor} smallPadding withPaddingBottom>
        <Container isMobileFull>
          <SectionTitle>{title}</SectionTitle>
          {/* upcoming update, to provide this part */}
          {/* <Typography tagName="p" variant="body" level="1">
            Kampus Rekomendasi Kami
          </Typography> */}
          <RecommendedCardsWrapper>
            <div
              className="campus-flex"
              css={css`
                display: flex;
              `}
            >
              {fetchStatus === 'pending' &&
                Array(5)
                  .fill(<Loader type="card" dataTestId="article-skeleton" />)
                  .map((CardSkeleton, idx) => (
                    <CardFlexItemWrapper key={`article-skeleton-${idx}`}>{CardSkeleton}</CardFlexItemWrapper>
                  ))}

              {fetchStatus === 'success' &&
                articles?.map((article: ArticleItem, i: number) => (
                  <CardFlexItemWrapper key={article.id}>
                    <CampusCard
                      dataTestId={`article-${i}`}
                      imageUrl={article.thumbnail?.url}
                      title={article.title}
                      description={article.description}
                      url={article.link}
                      onClick={() => onClick(article.title)}
                      linkProps={{
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      }}
                      useMarkdown
                    />
                  </CardFlexItemWrapper>
                ))}
            </div>
          </RecommendedCardsWrapper>
          <BasicContainer>
            {fetchStatus === 'success' && (
              <TextLink
                text="Lihat semua"
                className="recommended-articles__see-all"
                href="https://www.quipper.com/id/blog/category/quipper-campus/"
                linkProps={{
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }}
              />
            )}
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default RecommendedArticles
