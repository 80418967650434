import React, { FC } from 'react'

import SectionTitle from 'components/common/atoms/SectionTitle'
import SectionContainer from 'components/layouts/atoms/SectionContainer'
import Container from 'components/layouts/atoms/Container'
import CampusCard from 'components/cards/organisms/CampusCard'
import CardFlexItemWrapper from 'components/cards/atoms/CardFlexItemWrapper'
import TextLink from 'components/common/atoms/TextLink'
import RecommendedCardsWrapper from 'components/common/atoms/RecommendedCardsWrapper'

import { css } from '@emotion/core'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_SCHOOLS_RECOMMENDATION } from '@graphql/queries/schoolsRecommendation'
import { LIKE_SCHOOL } from '@graphql/mutations/likeSchool'
import { useTheme } from 'emotion-theming'
import Loader from 'components/common/atoms/Loader'
import BasicContainer from 'components/common/atoms/BasicContainer'
import { triggerEvent } from 'utils/dataLayer'
import { useRouter } from 'next/router'

type RecommendedCampusProps = {
  withPaddingBottom?: boolean
  page?: string
  title?: string
  seeAllUrl?: string
  eventParameter?: LooseObj
}

type School = {
  id: string
  images: {
    url: string
  } | null
  logo: {
    url: string
  } | null
  name: string
  liked: boolean
  slug: string
  summary: string
  sector: string
}

const RecommendedCampus: FC<RecommendedCampusProps> = ({
  withPaddingBottom = false,
  page = '/',
  title = 'Kampus Rekomendasi Kami',
  seeAllUrl = '/directory',
  eventParameter
}) => {
  const router = useRouter()

  const queryVars = {
    page,
    imagesSize: '256x128#c',
    logoSize: '64x64#c',
  }
  const { data, loading } = useQuery(GET_SCHOOLS_RECOMMENDATION, {
    variables: queryVars,
  })
  const schoolsRecommendation = data?.schoolsRecommendation
  const theme: ThemeType = useTheme()

  const [likeSchool] = useMutation(LIKE_SCHOOL, {
    update(cache, { data: { likeSchool } }) {
      const dataCache: { schoolsRecommendation: School[] } | null = cache.readQuery({
        query: GET_SCHOOLS_RECOMMENDATION,
        variables: queryVars,
      })
      const schoolsRecommendationCache = dataCache?.schoolsRecommendation
      cache.writeQuery({
        query: GET_SCHOOLS_RECOMMENDATION,
        data: {
          schoolsRecommendation: schoolsRecommendationCache?.map((school: School) => {
            if (school.id === likeSchool.id) {
              return { ...school, liked: likeSchool.liked }
            }
            return school
          }),
        },
      })
    },
  })

  const onClick = (school: School) => {
    const parameter = { campus_name: school.name }
    if (router.asPath === '/') {
      triggerEvent('home_campus_rec', parameter)
    } else if (router.pathname === '/majors/[slug]') {
      triggerEvent('major_campus_rec', {
        ...eventParameter,
        ...parameter,
        page_location: router.asPath,
     })
    }
  }

  if (!loading && schoolsRecommendation?.length === 0 && page !== '/') {
    return null
  }
  return (
    <div className="recommended-campus" id="related-campus">
      <SectionContainer backgroundColor="transparent" smallPadding withPaddingBottom={withPaddingBottom}>
        <Container isMobileFull>
          <SectionTitle>{title}</SectionTitle>
          {/* upcoming update, to provide this part */}
          {/* <Typography tagName="p" variant="body" level="1">
            Kampus Rekomendasi Kami
          </Typography> */}
          <RecommendedCardsWrapper>
            <div
              className="campus-flex"
              css={css`
                display: flex;
              `}
            >
              {!loading
                ? schoolsRecommendation?.map((school: School, i: number) => (
                    <CardFlexItemWrapper key={school.id}>
                      <CampusCard
                        dataTestId={`campus-${i}`}
                        imageUrl={school.images?.url}
                        logoUrl={school.logo?.url}
                        title={school.name}
                        key={school.id}
                        description={school.summary}
                        label={school.sector === 'public' ? 'NEGERI' : 'SWASTA'}
                        labelBackground={school.sector === 'public' ? theme.neutral.white : theme.campus.aqua30}
                        labelColor={school.sector === 'public' ? theme.gray.gray70 : theme.neutral.white}
                        url={`/directory/${school.slug}`}
                        isLiked={school.liked}
                        isLikeable
                        onClick={() => onClick(school)}
                        likeAction={() =>
                          likeSchool({
                            variables: { id: school.id },
                            optimisticResponse: {
                              __typename: 'Mutation',
                              likeSchool: {
                                id: school.id,
                                kind: 'school',
                                liked: !school.liked,
                                __typename: 'LikeSchoolPayload',
                              },
                            },
                          })
                        }
                      />
                    </CardFlexItemWrapper>
                  ))
                : Array(5)
                    .fill(<Loader type="card" dataTestId="campus-skeleton" />)
                    .map((CardSkeleton, idx) => (
                      <CardFlexItemWrapper key={`campus-skeleton-${idx}`}>{CardSkeleton}</CardFlexItemWrapper>
                    ))}
            </div>
          </RecommendedCardsWrapper>
          <BasicContainer>
            <TextLink text="Lihat semua" href={seeAllUrl} className="recommended-campus__see-all" />
          </BasicContainer>
        </Container>
      </SectionContainer>
    </div>
  )
}

export default RecommendedCampus
