import React, { FC } from 'react'

import NewTypography from 'components/common/atoms/NewTypography'

import BasicContainer from 'components/common/atoms/BasicContainer'

const SectionTitle: FC = ({ children }) => (
  <BasicContainer>
    <NewTypography tagName="h2" variant="heading" level="1" variantPC="title" levelPC="3">
      {children}
    </NewTypography>
  </BasicContainer>
)

export default SectionTitle
